import { useEffect } from "react";
import Svg from "react-inlinesvg";

import { Logo } from "Images";

import "./SplashPage.scss";

export const SplashPage = () => {
  useEffect(() => {
    document.title = "Thrage!";
  }, []);

  return (
    <div id="SplashPage">
      <Svg className="logo" src={Logo} />
    </div>
  );
};
