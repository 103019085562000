import { SpotifyPlaylist, CaptionedImage } from "Components";
import { useEffect } from "react";
import "./BandPage.scss";

import theme from "Styles/theme.scss";

const confidencePlaylist = "2L69eBbodkJxG1fwsTUYf2",
  bassPlaylist = "5iQZFWwIFsIrxJIKnZDIZp",
  drumPlaylist = "7hCCImoMGT8Gr8nqEdOJxA";

export const BandPage = () => {
  useEffect(() => {
    document.title = "Rock Trio Info";
  }, []);

  return (
    <div id="BandPage">
      <div style={{ height: 32, background: theme.background }} />
      <div className="blurbContainer alt">
        <CaptionedImage
          caption={(() => (
            <a
              href="https://www.facebook.com/LostboyPhotography13"
              target="_blank"
              rel="noreferrer"
            >
              <span style={{ fontStyle: "normal" }}>&#128247;:</span>&nbsp; Lost
              Boy Photography
            </a>
          ))()}
          src="/rock.jpg"
        />
        <span>
          <h1>New Rock Trio</h1>
          <p>
            Hi, I'm B. Michael Lee, and I'm currently looking to form a new,
            original-focused rock band in the Virginia Beach area. I have been
            actively gigging in the 757 since 2015, and am well-versed in
            navigating the Tidewater music scene. This area is a great place to
            be a musician, and I've truly enjoyed the time I've spent here, both
            as part of cover bands, and original acts as a "hired gun."
          </p>
          <p>
            I currently play guitar in a band called&nbsp;
            <a
              href="https://commitmentissuesband.com"
              target="_blank"
              rel="noreferrer"
            >
              Commitment Issues
            </a>
            . We have a great time, but I want to focus more on my own writing,
            and take on a dedicated guitar and lead vocal role. To that end, I'm
            looking for a drummer and bass player that want to really own a
            fresh, new project where they will have a high level of influence,
            and hit the local original music scene in a big way. If that sounds
            like your kinda' thing, read on! &#129304;
          </p>
        </span>
      </div>
      <div className="blurbContainer">
        <span>
          <h2>Frequently Asked Questions</h2>
          {[
            [
              "What genre do you want to play?",
              `I like to call it "straight-up rock." I owe most of my style to the video games I grew up playing; Mega Man, Final Fantasy, etc. To me, video game music is some of the best ever written. That in mind, I aim for a dynamic, energetic sound that is not too heavy, not too light, not too simple, not too progressive. Check out some of the Spotify playlists below for a good idea of the kind of music I like to play, and what skill level is appropriate for the project. There is excellent rock being written today, and I'm pretty happy with where the genre as a whole is right now. Let's get in on that!`,
            ],
            [
              "Are we going to do covers?",
              `Absolutely; I believe cover shows are the best way to get in front of people in the 757.  When I originally set out to form this band, I wanted to forego covers in favor of focusing entirely on original music. I've since reversed my stance, and would rather mix originals in at cover shows, if anything just to play out more often. You could say I'm obsessed with playing out; ideally there's a show every weekend in summer, two if we're lucky. If a relatively heavy gigging schedule sounds good to you, this may very well be your act, but I can't emphasize enough that the primary purpose of the band is to write new, original music; I don't plan to accept any "cover only" shows, and fully original rock shows/festivals will always take precedence. That said, a little supplemental income is nice.`,
            ],
            [
              "How often do you rehearse?",
              "Life happens, and we can't always make every rehearsal, but I like to keep a steady velocity of one rehearsal per week, more if something important is coming up (Original music festival, etc). One weekday night for 3 to 4 hours, typically.  In the beginning stages, most of that time is dedicated to writing original material, with an hour or so for shaping up what we already have, or covers that might need a little extra attention with the full band. In general, it's our responsibility to learn covers independently, outside of band rehearsal.",
            ],
            [
              "Do you have a practice space?",
              "Indubitably! And it's fully set up; no need to bring anything unless you really wanna'. I do have pets, though. We'll work something else out if you're allergic.",
            ],
            [
              "Wow, you seem pretty serious about this.",
              "What? That's not a question.  But, yeah! I care deeply about making a huge impact with this project, and am prepared to keep looking for as long as it takes to find the right people.",
            ],
            [
              "I'm in other band(s). Is that cool?",
              `Of course, but I only want to consider candidates who are prepared to make this their "main project."`,
            ],
            [
              "Does the band have a name?",
              `I have some ideas, but I'd rather get input from the full band before settling on things like that. For now, I just call it "The Rock Trio."`,
            ],
            [
              "Will I be expected to throw in on merch, recording time, etc.?",
              "Naw, bruh. We'll talk finances later.",
            ],
            [
              "Do I have to sing?",
              "That'd be awesome if you're willing to. I fully intend to own the lead vocal role, but backing vocals, harmonies and the occasional alternate lead vocalist sounds great to me!",
            ],
            [
              "Do you need a second guitar/keyboardist/didgeridoo/etc.?",
              "Not at this time. Additional members won't be fully off the table in the future, though.",
            ],
          ].map(([q, a]) => (
            <span key={q}>
              <p style={{ fontWeight: "bold" }}>{q}</p>
              <p>{a}</p>
            </span>
          ))}
        </span>
        <CaptionedImage src="/slap.jpg" caption="Alibis 2022" />
      </div>
      <div className="blurbContainer alt">
        <CaptionedImage
          caption="I get into all kinds of stuff."
          src="/keytar.jpg"
        />
        <span>
          <h2>Portfolio/Stuff I've done</h2>
          <p>
            I have always considered myself equally interested in guitar and
            vocals, and again, fully intend to own the dual role. I find it easy
            to work a crowd, and with the right rhythm section (and a red bull),
            I can be a veritable force of nature. Other than Commitment Issues,
            I've also been part of several other local acts, mostly as a
            guitarist. I still fully support all of these acts (and sometimes
            play with a few of them), and you should totally check them out:
            <ul>
              {[
                ["Stage Select", "https://www.facebook.com/stageselectband"],
                [
                  "Jared Farrell",
                  "https://www.facebook.com/jaredfarrellofficial",
                ],
                ["Kristi K Band", "https://www.facebook.com/kristikmusic"],
                [
                  "95 Reserve/Aaron Firestone",
                  "https://www.facebook.com/95reserveband",
                ],
              ].map(([li, l]) => (
                <li key={li}>
                  <a href={l} target="_blank" rel="noreferrer">
                    {li}
                  </a>
                </li>
              ))}
            </ul>
          </p>
          <h3>Videos</h3>
          <span>
            Here's a short playlist of some performances at Jerry's earlier this
            year with Commitment Issues, and a little backing track jammy:
          </span>
          <div className="youtubeVideos">
            {[
              "https://www.youtube.com/embed/videoseries?list=PL6lzlscJBH2EkW9Q9kwIvqPbeBjmvgVEo",
              "https://www.youtube.com/embed/ihT-_Tkjyu8",
            ].map((src) => (
              <iframe
                src={src}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="0"
              />
            ))}
          </div>
          <h3>Repertoire</h3>
          <span>
            I play each song on this Spotify playlist with full confidence as a
            vocalist and guitarist. If there's a lot of overlap here with songs
            you already know, putting the cover portion of the band together
            should be easy!
            <br />
            <br />
            <span style={{ fontStyle: "italic", fontSize: 12 }}>
              Approx. 3 hours 15 mins of music
            </span>
          </span>
          <div className="playlistWrapper">
            <SpotifyPlaylist playlistId={confidencePlaylist} />
          </div>
          <h3>Original Work</h3>
          <span>
            While I would rather have the full band involved with the new
            originals, I do have some sketches to check out if you want, find
            'em&nbsp;
            <a
              href="https://onedrive.live.com/?authkey=%21AEd7kRjqJhgNmh0&id=DB6CAAA05D965E6C%2110203&cid=DB6CAAA05D965E6C"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </span>
        </span>
      </div>
      <div className="blurbContainer">
        <span>
          <h2>Bass Players</h2>
          <p>
            Here's a list of songs - if you can play them, you're probably a
            pretty good match for the kind of music I have in mind:
            <div className="playlistWrapper">
              <SpotifyPlaylist playlistId={bassPlaylist} />
            </div>
            Some are easy-ish, some not so much, but the general sense of style
            is there.
            <br />
            <br />
            <h3>Other considerations for bass</h3>
            <ul>
              <li>No need to use a 5-string, but Drop D tuning is essential</li>
              <li>Bring some effects! Everybody loves a good fuzz!</li>
            </ul>
          </p>
        </span>
        <CaptionedImage
          src="/bass.jpg"
          caption="Sometimes I'll throw down on the low-end, too."
        />
      </div>
      <div className="blurbContainer alt">
        <CaptionedImage
          caption="Pictured: My usual drummer"
          src="/drumcat.jpg"
        />
        <span>
          <h2>Drummers</h2>
          <p>
            If you can play these, you're probably an excellent match for this
            project:
            <div className="playlistWrapper">
              <SpotifyPlaylist playlistId={drumPlaylist} />
            </div>
            If you LIKE these, you're a perfect match for this project!
            <br />
            <br />
            <h3>Other considerations for drums</h3>
            <ul>
              <li>Double kick highly encouraged</li>
            </ul>
          </p>
        </span>
      </div>
      <div className="blurbContainer">
        <span>
          <h2>Audition</h2>
          <p>
            Alright, that was a lot of stuff to go over! If you've read this
            far, and you think you'd be a good match for the project, please
            shoot me an email at{" "}
            <a href="mailto:mikeleeauditions@gmail.com">
              mikeleeauditions@gmail.com
            </a>
            . If you don't mind, please let me know when you're next available
            to attend an audition while you're at it. We'll meet up in a public
            place first, and then head to the jamspace after a quick interview.
          </p>
          <p>
            Thanks so much for your time, and I hope to hear from (and jam with)
            you soon!
          </p>
        </span>
        <CaptionedImage caption="" src="/hair.jpg" />
      </div>
    </div>
  );
};
