import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { SplashPage, BandPage } from "Components";

import "./App.scss";

export const App = () => (
  <>
    <Router>
      <Routes>
        <Route path="band/*" element={<BandPage />} />
        <Route path="*" element={<SplashPage />} />
      </Routes>
    </Router>
  </>
);
