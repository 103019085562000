import "./CaptionedImage.scss";

export const CaptionedImage = ({
  caption = "Caption",
  showCaption = true,
  size = 300,
  src = "https://placekitten.com/300/300",
  ...props
}) => (
  <div className="CaptionedImage" {...props}>
    <div
      style={{ backgroundImage: `url(${src})`, width: size, height: size }}
    ></div>
    {showCaption && <span>{caption}</span>}
  </div>
);
