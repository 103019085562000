export const SpotifyPlaylist = ({ playlistId }) => (
  <iframe
    title={`Playlist ${playlistId}`}
    src={`https://open.spotify.com/embed/playlist/${playlistId}?theme=0`}
    width="100%"
    height="380"
    frameBorder="0"
    allowfullScreen="0"
    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
  />
);
